import { cloneDeep, pick } from 'lodash'
import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  namespaced: true,
  state: {
    mapId: null,
    mapScreenshotUrl: null
  },
  mutations: {
    SET_MAP_ID(state, { mapId }) {
      state.mapId = mapId;
      console.log('mapId', mapId)
    },
    SET_MAP_SCREENSHOT_URL(state, { url }) {
      state.mapScreenshotUrl = url;
    },
    RESET_STATE(state) {
      state.mapId = null;
    }
  },
  getters: {
    sharingLink(state) {
      const baseUrl = process.env.VUE_APP_BASE_URL || 'http://localhost:8080';
      return state.mapId ? `//${baseUrl}/map/${state.mapId}/results` : '';
    }
  },
  actions: {
    resetState({ commit }) {
      commit('RESET_STATE');
    },
    async saveSharedMap({}, { data }) {
      const fn = firebase.functions().httpsCallable('saveSharedMap');
      const result = await fn(data);
      
      return result.data;
    },
    async prepareAndSaveMap({ dispatch, commit, state, rootState, rootGetters }, { data=null }={}) {
      commit('SET_LOADING', { loading: true }, { root: true });
      try {
        let copiedState = cloneDeep(pick(data || rootState, [
          'mode',
          'address',
          'displayAddress',
          'shapes',        
          'globalZIndex',
          'mapZoom',
          'mapCenter',
          'projectName'          
        ]));
        delete copiedState.mapCenter['fromMap'];
        delete copiedState.mapZoom['fromMap'];
    
        // Copy the convertedCalculated and suffix properties from the shapes getter to the copiedState shapes property
        copiedState.shapes = copiedState.shapes.map(shape => {
          let shapeGetter = rootGetters.shapes.find(s => s.indexID === shape.indexID);
          return {
            ...shape,
            convertedCalculated: shapeGetter ? shapeGetter.convertedCalculated : shape.convertedCalculated,
            suffix: shapeGetter ? shapeGetter.suffix : shape.suffix
          };
        });
    
        copiedState.totalShapeCalculatedConverted = rootGetters.totalShapeCalculatedConverted;
        copiedState.totalShapeCalculatedRaw = rootGetters.totalShapeCalculatedRaw;
    
        copiedState.meta = {
          mapId: state.mapId,
          saved: Date.now(),
          utm: rootState.utm,
          schemaVersion: (data) ? data.meta.schemaVersion : process.env.VUE_APP_SAVE_SCHEMA_VERSION,
          sessionUuid: rootState.uuid,          
          customerFirstName: rootState.contact.firstName,
          customerLastName: rootState.contact.lastName,
          customerEmail: rootState.contact.email,
          customerPhone: rootState.contact.phone,
          customerProductInterest: rootState.contact.productInterest,
          customerAgree: rootState.contact.agree
        }      
    
        return await dispatch('saveSharedMap', { data: copiedState });
      } catch (e) {
        throw new Error(e);
      } finally {
        commit('SET_LOADING', { loading: false }, { root: true });
      }
    },
    
    async shareMap({ dispatch, commit }, payload) {
      const result = await dispatch('prepareAndSaveMap', payload);
      const { mapId, mapScreenshotUrl, meta } = result;
      
      commit('SET_MAP_ID', { mapId });
      commit('SET_MAP_SCREENSHOT_URL', { url: mapScreenshotUrl });
      commit('SET_META', meta, { root: true });

      return result;
    },
    
    async updateSharedMap({ dispatch, commit }, payload) {
      const { mapId, mapScreenshotUrl, meta } = await dispatch('prepareAndSaveMap', payload);
      commit('SET_MAP_ID', { mapId });
      commit('SET_MAP_SCREENSHOT_URL', { url: mapScreenshotUrl });
      commit('SET_META', meta, { root: true });
    },
   
    async getSharedMap({ commit, rootState }, { mapId }) {
      commit('SET_LOADING', { loading: true }, { root: true });
      try {
        // Call the Cloud Function to load the map
        const loadMap = firebase.functions().httpsCallable('getSharedMap');
        const result = await loadMap({ mapId });
        
        // Check if the map exists
        if (!result.data) {
          throw new Error('Map not found');
        }

        result.data.mapCenter = { ...result.data.mapCenter, fromSave: true }        

        // Return the map data
        return { data: result.data, mapId };
      } catch (e) {
        throw new Error(e);
      } finally {
        commit('SET_LOADING', { loading: false }, { root: true });
      }
    }
  }
}