/* actions.js */
import { cloneDeep, omit, pick, get, has, debounce } from 'lodash'
import { NEW_AREA } from '@/constants.js'
import { units, default_units } from '@/app-config'

export default {
  async initializeStore ({ commit, dispatch }, { mapId } = {}) {
    if (mapId) {
      console.log('getting shared map');
      await dispatch('resetState');
      
      const { data, mapId: returnMapId } = await dispatch('mapSharing/getSharedMap', { mapId });
      commit('INITIALIZE_STORE', data);
      commit('mapSharing/SET_MAP_ID', { mapId: returnMapId });

      if (data.hasOwnProperty('meta')) {  
        console.log('meta', data.meta)      
        const properties = {
          'customerFirstName': ['SET_FIRST_NAME', 'firstName'],
          'customerLastName': ['SET_LAST_NAME', 'lastName'],
          'customerEmail': ['SET_EMAIL', 'email'],
          'customerPhone': ['SET_PHONE', 'phone'],
          'customerProductInterest': ['SET_PRODUCT_INTEREST', 'productInterest'],
          'projectName': ['SET_PROJECT_NAME', 'projectName']
        }

        Object.keys(properties).forEach(property => {
          const [mutation, key] = properties[property];
          if (data.meta.hasOwnProperty(property)) {
            commit(`contact/${mutation}`, { [key]: data.meta[property] }, { root: true });
          }
        });
      }
    } else {    
      commit('INITIALIZE_STORE');
    }
  },
  async initializeBackend ({ dispatch }) {
    await dispatch('backend/initialize');
    await dispatch('siteConfig/fetchConfig');
  },
  searchAddress ({commit}, address) {
    // reset the address
    
    commit('SET_ADDRESS', null);
    commit('SET_MAP_CENTER', null);
    setTimeout(() => {      
      commit('SET_ADDRESS', address);
      commit('SET_DISPLAY_ADDRESS', address);
    }, 500);

  },
  setDisplayAddress ({commit}, address) {
    commit('SET_DISPLAY_ADDRESS', address);
  }, 
  refreshShape: debounce(({commit}, shape) => {
    commit('REFRESH_SHAPE', shape.getState());
  }, 100, { trailing: true }),
  refreshAllShapes (context) {      
    context.state.shapes.forEach((shape) => {        
      context.commit('REFRESH_SHAPE', shape)
    });      
  },
  hoverShape ({state, commit}, shape) {
    state.shapes.forEach((s, idx) => {
      commit('SET_SHAPE_HIGHLIGHT', {
        idx,
        highlight: (shape && shape.indexID == s.indexID) ? true : false
      })
    });
  },
  setMode ({commit}, mode) {
    commit('SET_MODE', mode);
  },
  setCurrentControlOverlay ({ commit, state }, payload) {
    if (payload.hasOwnProperty('current')) {
      if (get(state, 'currentControlOverlay.current') && state.currentControlOverlay.uuid != payload.uuid) {
        console.log('set visible: false');
        state.currentControlOverlay.current.setVisible(false);
      }

      commit('SET_CURRENT_CONTROL_OVERLAY', payload)
    }
  },
  setToolType ({commit}, type) {
    commit('SET_TOOL_TYPE', type);
  },
  setMapCenter ({ commit }, latLng) {
    commit('SET_MAP_CENTER', latLng);
  },
  setMapDrag ({ commit }, dragging) {
    commit('SET_MAP_DRAG', dragging);
  },
  setToolbarsHidden({ commit }, hidden) {
    commit('SET_TOOLBARS_HIDDEN', hidden);
  },
  setShapeCutout ({commit}, cfg) {
    if (cfg.shape && cfg.hasOwnProperty('cutout')) {
      commit('SET_SHAPE_CUTOUT', {
        indexID: cfg.shape.indexID,
        cutout: cfg.cutout
      });
    }
  },
  setShapeDistanceVisible ({ commit }, cfg) {
    if (cfg.shape && cfg.hasOwnProperty('visible')) {
      commit('SET_SHAPE_DISTANCE_VISIBILE', {
        indexID: cfg.shape.indexID,
        visible: cfg.visible
      });
    }
  },
  setShapeLabelText ({ commit }, cfg) {
    if (cfg.shape && cfg.hasOwnProperty('labelText')) {
      commit('SET_SHAPE_LABEL_TEXT', {
        indexID: cfg.shape.indexID,
        labelText: cfg.labelText
      });
    }
  },
  setCurrentShape ({commit, state}, shape) {
    commit('SET_CURRENT_SHAPE', (shape) ? shape.indexID : null);
  },
  deleteShape ({commit, dispatch}, { shape, skipConfirm=false }={}) {    
    console.log('in deleteshape');
    if (shape) {      
      const labelText = ((shape.indexID) ? (has(shape, 'labelText') ? shape.labelText : shape.getLabelText()) : '');
      const type = ((shape.indexID) ? (has(shape, 'type') ? shape.type : shape.getType()) : '');

      const removeFn = () => {
        commit('REMOVE_SHAPE', shape);
        commit('SET_CURRENT_SHAPE', null);
        commit('SET_MODE', 'move');
        commit('RESET_DISPLAY_INDEXES');
      }

      // only ask for confirmation if a real shape exists
      if (shape.indexID && !skipConfirm) {
        dispatch('dialogs/confirm', { 
          priority: 0, 
          message: ((labelText.length > 0) ? `Are you sure you want to delete the <b>${labelText}</b> ${type} measurement?` : `Are you sure you want to delete this ${type} measurement?`) + ' This cannot be undone.',
          config: {
            title: 'Confirm',
            confirmText: 'Delete',
            cancelText: 'Don\'t delete',
            type: 'is-danger',
            onConfirm: removeFn            
          }
        });   

        return;
      }   

      removeFn();
    }
  },
  clearShapes ({commit, state}) {
    state.shapes.map(a => a.indexID).forEach(indexID => commit('REMOVE_SHAPE', indexID));
  },
  replaceShape ({commit}, repl) {
    commit('REPLACE_SHAPE', repl);
  },
  clearAddress ({commit}) {
    commit('SET_ADDRESS', '');
  },
  initNewMap ({ commit, dispatch }) {
    commit('SET_CURRENT_SLOT', '');
    dispatch('resetState');
  },
  setProjectName ({commit}, { projectName }) {
    commit('SET_PROJECT_NAME', projectName);
  },
  async resetState ({ commit, dispatch }) {
    commit('RESET_STATE');

    await dispatch('mapSharing/resetState');
    await dispatch('dialogs/resetState');
    await dispatch('contact/resetState');
    await dispatch('saveLocalStorageState');
  },
  initNewShape ({commit, state}, shape) {
    commit('REPLACE_SHAPE', {
      idx: state.shapes.findIndex((shape) => shape instanceof NEW_AREA),
      shape
    });
  },
  addShape ({commit, getters}, shape) {
    if (shape) {
      shape.labelText = 'Area';
      
      commit('ADD_SHAPE', shape);
      commit('RESET_DISPLAY_INDEXES');
    }
  },
  setMapEditingEnabled ({commit}, enabled) {
    commit('SET_MAP_EDITING_ENABLED', enabled);
  },
  setSaveModalVisibility ({commit}, visibility) {
    commit('SET_SAVE_MODAL_VISIBLE', visibility);
  },
  setTutorialModalVisibility ({commit}, visibility) {
    commit('SET_TUTORIAL_MODAL_VISIBLE', visibility);
  },
  setAffiliateModalVisibility ({commit}, visibility) {
    commit('SET_AFFILIATE_MODAL_VISIBLE', visibility);
    commit('SET_AFFILIATE_MODAL_SHOWN');
  },
  setSearchModalVisibility ({commit}, visibility) {
    commit('SET_SEARCH_MODAL_VISIBLE', visibility);
  },
  setUnitModalVisibility ({commit}, visibility) {
    commit('SET_UNIT_MODAL_VISIBLE', visibility);
  },
  increaseGlobalZIndex ({commit, state}) {
    commit('SET_GLOBAL_ZINDEX', state.globalZIndex + 1);
  },
  setIntroTipsShown ({commit}) {
    commit('SET_INTRO_TIPS_SHOWN');
  },
  setPublicComputerWarningShown ({commit}) {      
    commit('SET_PUBLIC_COMPUTER_WARNING_SHOWN');
  },
  setImperialState (context, status) {
    context.commit('SET_IMPERIAL', status);
  },
  setPrimaryUnit (context, unitPayload) {
    if (!unitPayload.name || !units.find(u => unitPayload.name == u.name && unitPayload.type == u.type)) {
      unitPayload = context.getters.currentDefaultUnit;
    }
    
    context.commit('SET_PRIMARY_UNIT', unitPayload);    
  },
  setLastMapClickEvent ({commit}, { event, delayedClear=false }) {
    commit('SET_LAST_MAP_CLICK_EVENT', event);

    if (delayedClear) {
      setTimeout(() => commit('SET_LAST_MAP_CLICK_EVENT', null), 500);
    }
  },
  setActiveStatusTab ({commit}, payload) {
    console.log('tab', payload);
    commit('SET_ACTIVE_STATUS_TAB', payload.tab);
  },
  setAppClicked ({ commit }) {    
    commit('SET_APP_CLICKED', { clicked: true });
    setTimeout(() => commit('SET_APP_CLICKED', { clicked: false }), 100);
  },
  setUtm ({ commit }, utm) {
    commit('SET_UTM', utm);
  },
  async delete({ dispatch, commit }, { name }) {
    commit('SET_LOADING', { loading: true });
    try {   
      await dispatch('backend/storage/deleteSlot', { name }, { root: true });
    } catch (e) {
      throw new Error(e);      
    } finally {
      commit('SET_LOADING', { loading: false });
    }
  },
  async load({ dispatch, commit }, { name }) {
    commit('SET_LOADING', { loading: true });
    try {
      const slot = await dispatch('backend/storage/loadSlot', { name }, { root: true });

      if (get(slot, 'meta.schemaVersion', 0) != process.env.VUE_APP_SAVE_SCHEMA_VERSION) {        
        throw new Error('This map has an invalid schema version and cannot be loaded.');         
      }

      commit('INITIALIZE_STORE', {...slot, 'currentSlotName': name });
    } catch (e) {
      throw new Error(e);
    } finally {
      commit('SET_LOADING', { loading: false });
    }    
  },
  saveLocalStorageState ({ state }) {
    let copy = omit(state, ['backend','tooltips','estimator','dialogs','currentControlOverlay']);        
    localStorage.setItem('store', JSON.stringify(copy));;
  },
  async save({ dispatch, commit, state }, { name, data=null }) {
    commit('SET_LOADING', { loading: true });
    try {
      let copiedState = copiedState = cloneDeep(pick(data || state, [
        'mode',
        'address',
        'shapes',        
        'globalZIndex',
        'mapZoom',
        'mapCenter',
        'projectName'
      ]));

      delete copiedState.mapCenter['fromMap'];
      delete copiedState.mapZoom['fromMap'];

      copiedState.meta = {
        saved: Date.now(),        
        name: name,        
        schemaVersion: (data) ? data.meta.schemaVersion : process.env.VUE_APP_SAVE_SCHEMA_VERSION,
        sessionUuid: state.uuid 
      }      
      
      await dispatch('backend/storage/saveSlot', { name, data: copiedState }, { root: true });
      state.currentSlotName = name;
    } catch (e) {
      throw new Error(e);
    } finally {
      commit('SET_LOADING', { loading: false });
    }
  }
}