<template>
  <b-carousel
    ref="carousel"
    @hook:mounted="carouselUpdated"
    v-model="currentCarouselItem"
    :arrow="false"
    :pause-info="false"
    :pause-hover="false"
    :autoplay="false"
  >
    <b-carousel-item v-for="(carouselItem, i) in items" :key="i">
      <p v-html="carouselItem.text"></p>
      <div v-if="carouselItem.tipSrc" class="tip-ct content has-text-centered">
        <video :id="`tip-${i}`" loop playsinline muted>
          <source
            :src="`${publicPath}${carouselItem.tipSrc}`"
            type="video/mp4"
          />
        </video>
      </div>
      <div v-else>
        <p v-html="carouselItem.text"></p>
      </div>
    </b-carousel-item>
  </b-carousel>
</template>

<style scoped>
p {
  text-align: center;
}
div.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: stretch;
  overflow: auto;
}
video {
  width: 100%;
  height: 45vh;
  object-fit: contain;
}
</style>

<script>
export default {
  name: 'Carousel',
  emits: ['carousel-item-changed'],
  props: {
    items: {
      type: Array,
      default: () => []
    },
    currentItem: {
      type: Number,
      default: 0
    },
    publicPath: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      item: 0
    }
  },
  watch: {
    currentItem: {
      handler(newIdx, oldIdx) {
        this.handleVideoChange(newIdx, oldIdx);
      }
    }
  },
  computed: {
    currentCarouselItem: {
      get() {
        return this.currentItem;
      },
      set(val) {
        this.$emit('carousel-item-changed', val);
      }
    }
  },
  methods: {
    handleVideoChange(newIdx, oldIdx) {
      console.log('next', newIdx, 'prev', oldIdx);
      this.$nextTick(() => {
        if (oldIdx !== undefined) {
          let lastItem = this.$refs.carousel.$el.querySelector(`#tip-${oldIdx}`);
          if (lastItem) {
            lastItem.pause();
            lastItem.currentTime = 0;
          }
        }
        let nextItem = this.$refs.carousel.$el.querySelector(`#tip-${newIdx}`);
        if (nextItem) {
          nextItem.play();
          nextItem.addEventListener('canplaythrough', () => nextItem.play());
        }
      });
    },
    carouselUpdated() {
      this.handleVideoChange(this.item);
    },
    nextTip() {
      if (this.item < this.items.length - 1) {
        this.item++;
        return;
      }
      this.item = 0;
    },
    prevTip() {
      if (this.item > 0) {
        this.item--;
        return;
      }
      this.item = this.items.length - 1;
    },
  }
}
</script>