<template>
  <div :class="toolbarClasses" v-if="showSharingDetails"> 
    <div class="toolbar-expanded-items" v-bind:style="toolbarStyle"> 
      <div class="map-attributes">
        <div :class="logoClasses">
          <a :href="config.homepage" target="_blank"><img :src="`${clientResourcesUrl}logo.png?alt=media`" alt="App Logo" class="app-logo"></a>
        </div>
        <div class="attrs">
          <h1 class="attr-header">Project Description</h1>
          <p class="attr-description">{{ projectName }}</p>
        </div>
        <div class="attrs address">
          <h1 class="attr-header">Address</h1>
          <p class="attr-description">{{ formattedAddress }}</p>
        </div>
      </div>
      <div class="toolbar-controls show">
        <a href="#" title="Cancel editing" @click.prevent="cancelEditing">
          <span>cancel</span>
          <span class="icon">
            <cancel-icon />
          </span>
        </a>
        <a href="#" title="Save changes" @click.prevent="saveMap">
          <span>save</span>
          <span class="icon">
            <content-save-icon />
          </span>
        </a>
      </div>      
    </div>
  </div>
</template>

<style scoped>
  .toolbar-container {    
    position: fixed;
    top: 0;    
    left: 0;
    width: 100%;    
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    padding: .8em;
    background-color: #fff;
    display: flex;
    flex-direction: row;
  }

  .toolbar-container.collapsed {
    height: 0;
    overflow: hidden;
  }

  div.toolbar-expanded-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
  }

  div.toolbar-container.xs div.toolbar-expanded-items, div.toolbar-container.xs div.toolbar-expanded-items div.map-attributes {
    flex-direction: column;
    align-items: flex-start;
  }

  div.map-attributes {
    display: flex;
  }

  .toolbar-container.collapsed {
    height: 0;
    overflow: hidden;
  }

  .toolbar-container div.attrs {
    border-left: 1px solid lightgrey;
    padding: 0 .5em;
  }

  .app-logo {
    width: 100px;
    padding-right: 1em;
  }

  div.level-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

  }

  div.attrs > * {
    display: block;
  }

  div.attrs.address {
    flex-wrap: wrap;
    flex-shrink: 3;
    flex-grow: 0;
    word-wrap: break-word;
  }

  div.attrs h1 {
    font-size: .8em;
    font-weight: bold;
  }

  div.results {
    display: flex;
    flex-shrink: 0;
    flex-direction: row;
    justify-content: flex-start;
  }

  .toolbar-controls, .visibility-toolbar {
    display: flex;
    flex-direction: row;
    
    /* align-self: flex-end; */
    justify-content: flex-end;
    flex-wrap: wrap;
    flex-shrink: 1;
    flex-grow: 3;
  }

  .toolbar-controls a, .visibility-toolbar a {
    display: block;
    border-radius: 10px;
    padding: 2px 10px;
    margin: 2px;
    border: 1px solid lightgrey;
    font-size: .8em;
    background-color: #f0f0f0;
    min-width: 100px;
    text-align: center;
  }

  div.toolbar-controls a:hover, div.visibility-toolbar a:hover {
    box-shadow: 0 0 3px #000;
  }

  .toolbar-controls a {
    color: #000;
  }  

  div.toolbar-container.collapsed {
    background-color: transparent;
  }

  div.toolbar-container.xs div.toolbar-controls, div.visibility-toolbar.hide {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  div.toolbar-container.collapsed div.toolbar-controls.hide a, div.visibility-toolbar.hide a {
    border-radius: 0 0 10px 10px;
    border-bottom: 1px solid lightgrey;
  }

  /* add a hover style to toolbar-container */
  div.toolbar-controls a:hover {
  }

  .visibility-toolbar {
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 10px 10px 0 0;
  }

  div.visibility-toolbar:hover {
  }

  div.visibility-toolbar:hover a {
    color: #fff;
  }

  .visibility-toolbar a {
    color: #000;
  }  
  
</style>

<script>
  window.Vue = require('vue').default;

  import Vuex from 'vuex'  
  
  export default {
    data: function() {
      return {}
    },
    computed: {

      hide() {
        return this.$store.state.mapDrag || this.$store.state.toolbarsHidden;
      },
      showSharingDetails() {
        return (this.$route.meta.showSharingDetails) ? true : false;
      },
      toolbarStyle() {
        return {
          opacity: this.hide ? 0 : 1,
          visibility: this.hide ? 'hidden' : 'visible',
          transition: 'opacity 0.5s ease, visibility 0.5s ease'
        };
      },
      toolbarClasses() {
        const classes = ['toolbar-container','level', this.$mq];              
        if (this.hide) {
          classes.push('collapsed');
        }

        return classes;
      },
      logoClasses() {
        const classes = ['logo-container'];
        if (this.$mq === 'xs') {
          classes.push(this.$mq);          
          return classes;
        }

        classes.push('level-item');
        return classes;
      },
      totalAreaString() {
        return (this.totalShapeCalculatedConverted) ? `${this.totalShapeCalculatedConverted.toLocaleString()} ${this.measureSuffix}` : '---';
      },
      projectName() {
        let name = this.$store.state.projectName || 'Measurement Results';
        if (name.length > 20 && this.$mq == 'sm') {
          name = name.substring(0, 30) + '...';
        }

        return name;
      },
      formattedAddress() {
        return this.displayAddress || this.address || '---'
      },
      ...Vuex.mapGetters([
        'totalShapeCalculatedConverted',
        'measureSuffix',
      ]),
      ...Vuex.mapState([
        'address',
        'displayAddress'
      ]),
      ...Vuex.mapState('mapSharing', [
        'mapId'
      ]),      
    },
    methods: {
      toggleToolbarVisibility() {
        this.$store.dispatch('setToolbarsHidden', !this.$store.state.toolbarsHidden);
      },
      cancelEditing() {
        this.$store.dispatch('initializeStore', { mapId: this.mapId});
        this.$router.replace({ name: 'MapResults' });
      },
      async saveMap() {
        try {
          this.$store.commit('SET_LOADING', { loading: true }, { root: true });
          await this.$store.dispatch('mapSharing/updateSharedMap');
          this.$router.push({ name: 'MapResults' });

          this.$store.dispatch('dialogs/snackbar', { message: 'Your measurements have been saved.' });
        } catch (error) {
          this.$store.dispatch('dialogs/alert', {
            priority: 0,            
            message: `Could not save your measurements. ${error.message}`,
            config: { title: 'Error', type: 'is-error' }
          });

          throw error;
        } finally {        
          this.$store.commit('SET_LOADING', { loading: false }, { root: true });
        }
      },
      editMap() {
        this.$router.push({ name: 'MapResultsEdit' });
      }      
    },
    mounted() {
      
    }
  }
</script>
