/* icons.js */

import Vue from 'vue';
import 'vue-material-design-icons/styles.css';

/* used in control panel */
import MapPlus from 'vue-material-design-icons/MapPlus.vue';
import Magnify from 'vue-material-design-icons/Magnify.vue';
import ContentSave from 'vue-material-design-icons/ContentSave.vue';
import TapeMeasure from 'vue-material-design-icons/TapeMeasure.vue';
import Draw from 'vue-material-design-icons/Draw.vue';
import CloseCircle from 'vue-material-design-icons/CloseCircle.vue';
import ShapePolygonPlus from 'vue-material-design-icons/ShapePolygonPlus.vue';
import ShapeRectanglePlus from 'vue-material-design-icons/ShapeRectanglePlus.vue';
import Check from 'vue-material-design-icons/Check.vue';
import Help from 'vue-material-design-icons/Help.vue';
import Ruler from 'vue-material-design-icons/Ruler.vue';

/* used on status panel */
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue';
import ChevronUp from 'vue-material-design-icons/ChevronUp.vue';
import Information from 'vue-material-design-icons/Information.vue';
import Pencil from 'vue-material-design-icons/Pencil.vue'

/* used on control and handle overlays */
import Edit from 'vue-material-design-icons/VectorPolylineEdit.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import Move from 'vue-material-design-icons/CursorMove.vue'
import Done from 'vue-material-design-icons/Check.vue'

/* used on open/save modal */
import Open from 'vue-material-design-icons/OpenInNew.vue'

/* used on product estimater */
import Seed from 'vue-material-design-icons/Seed.vue'
import Sprout from 'vue-material-design-icons/Sprout.vue'
import Cart from 'vue-material-design-icons/Cart.vue'
import Calculator from 'vue-material-design-icons/Calculator.vue'
import ArrowLeft from 'vue-material-design-icons/ArrowLeftCircleOutline.vue'
import ArrowRight from 'vue-material-design-icons/ArrowRightCircleOutline.vue'

import Account from 'vue-material-design-icons/Account.vue'
import AccountCheck from 'vue-material-design-icons/AccountCheck.vue'
import Menu from 'vue-material-design-icons/Menu.vue'
import Logout from 'vue-material-design-icons/Logout.vue'

import Polygon from 'vue-material-design-icons/VectorPolygon.vue'
import ArrowExpand from 'vue-material-design-icons/ArrowExpand.vue'

/* used on local save dialog */
import Alert from 'vue-material-design-icons/Alert.vue'

/* used on subscribe modal */
import PodiumBronze from 'vue-material-design-icons/PodiumBronze.vue'
import PodiumSilver from 'vue-material-design-icons/PodiumSilver.vue'
import PodiumGold from 'vue-material-design-icons/PodiumGold.vue'

import Cog from 'vue-material-design-icons/Cog.vue'
import Map from 'vue-material-design-icons/Map.vue'
import Cancel from 'vue-material-design-icons/Cancel.vue'

Vue.component('map-plus-icon', MapPlus);
Vue.component('magnify-icon', Magnify);
Vue.component('content-save-icon', ContentSave);
Vue.component('draw-icon', Draw);
Vue.component('close-circle-icon', CloseCircle);
Vue.component('tape-measure-icon', TapeMeasure);
Vue.component('shape-polygon-plus-icon', ShapePolygonPlus);
Vue.component('shape-rectangle-plus-icon', ShapeRectanglePlus);
Vue.component('check-icon', Check);
Vue.component('help-icon', Help);
Vue.component('chevron-down-icon', ChevronDown);
Vue.component('chevron-up-icon', ChevronUp);
Vue.component('vector-polyline-edit-icon', Edit);
Vue.component('delete-icon', Delete);
Vue.component('cursor-move-icon', Move);
Vue.component('check-icon', Done);
Vue.component('ruler-icon', Ruler);

Vue.component('open-icon', Open);

Vue.component('seed-icon', Seed);
Vue.component('sprout-icon', Sprout);
Vue.component('cart-icon', Cart);
Vue.component('calculator-icon', Calculator);
Vue.component('arrow-left-icon', ArrowLeft);
Vue.component('arrow-right-icon', ArrowRight);
Vue.component('information-icon', Information);
Vue.component('pencil-icon', Pencil);

Vue.component('menu-icon', Menu);
Vue.component('account', Account);
Vue.component('account-check', AccountCheck);
Vue.component('logout-icon', Logout);

Vue.component('alert-icon', Alert);

Vue.component('podiumbronze-icon', PodiumBronze);
Vue.component('podiumsilver-icon', PodiumSilver);
Vue.component('podiumgold-icon', PodiumGold);

Vue.component('cog-icon', Cog);
Vue.component('map-icon', Map);

Vue.component('vector-polygon-icon', Polygon);
Vue.component('arrow-expand-icon', ArrowExpand);
Vue.component('cancel-icon', Cancel);

