/* highlevel-crm.js */

import firebase from 'firebase/app'
import 'firebase/functions'

export default {
  namespaced: true,
  actions: {
    async createContactAndOpportunity({}, { data }) {
      const createFn = firebase.functions().httpsCallable('createContactAndOpportunity');
      const result = await createFn(data);
      return result.data;
    },
    async updateOpportunity({}, { data }) {
      const updateFn = firebase.functions().httpsCallable('updateOpportunity');
      const result = await updateFn(data);
      return result.data;
    }
  }
}
