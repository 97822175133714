export default {
  methods: {
    gTagEvent({ a = 'Click', c, l, v = 1 } = {}) {
      if (typeof gtag === 'function') {
        console.log('sendtag: ', a, c, l, v);
        gtag('event', a, {
          'event_category': c,
          'event_label': l,
          'value': v
        });
      }
    }
  }
}