<!-- LeadWorkflowNavigationButtons.vue -->
<template>
  <footer class="modal-card-foot">
    <b-button 
      class="button prev-button"
      @click="handlePrevClick" 
      @mouseup="handlePrevMouseUp"
      v-show="prevRoute || showPrev"
      :disabled="!prevButtonEnabled"
    >
    <component v-if="prevButtonIcon" :is="prevButtonIcon"></component>{{ prevButtonLabel }}
    </b-button>  
    <b-button 
      class="button is-primary next-button" 
      expanded 
      @click="handleNextClick" 
      @mouseup="handleNextMouseUp"
      v-show="nextRoute || showNext"
      :disabled="!nextButtonEnabled"
    >
      <component v-if="nextButtonIcon" :is="nextButtonIcon"></component>{{ nextButtonLabel }}
    </b-button>
  </footer>
</template>

<script>
export default {
  emits: ['prev-button-clicked', 'next-button-clicked', 'next-button-mouseup', 'prev-button-mouseup'],
  props: {
    nextButtonLabel: {
      type: String,
      default: 'Next Step'
    },
    nextButtonEnabled: {
      type: Boolean,
      default: true
    },
    nextButtonIcon: {
      type: String,
      default: ''
    },
    prevButtonLabel: {
      type: String,
      default: 'Previous Step'
    },
    prevButtonEnabled: {
      type: Boolean,
      default: true
    },
    prevButtonIcon: {
      type: String,
      default: ''
    },
    showNext: {
      type: Boolean,
      default: false
    },
    showPrev: {
      type: Boolean,
      default: false
    },
    nextRoute: {
      type: String,
      default: null
    },
    prevRoute: {
      type: String,
      default: null
    }
  },
  methods: {
    handleNextClick() {
      this.$emit('next-button-clicked');

      if (this.nextRoute){        
        this.$router.push(this.nextRoute);
      }      
    },
    handlePrevClick() {
      this.$emit('prev-button-clicked');

      if (this.prevRoute) {       
        this.$router.push(this.prevRoute);
      }      
    },
    handleNextMouseUp() {
      this.$emit('next-button-mouseup');
    },
    handlePrevMouseUp() {
      this.$emit('prev-button-mouseup');
    }
  }
}
</script>

<style scoped>

.modal-card-foot {
  border-top: none !important;
}

/* make the buttons #467839 */
.modal-card-foot .button {
  color: #fff;
  background-color: #467839;
  border-color: #467839;
}

/* a nice hover color for button */
.modal-card-foot .button:hover {
  background-color: #3a5f2b;
  border-color: #3a5f2b;
}

button span.material-design-icon {
  margin-right: 5px;
}
</style>