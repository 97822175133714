<template>
  <b-modal :full-screen="$mq == 'xs'" class="flow-modal" :can-cancel="false" :has-modal-card="true" scroll="keep" :active="true">
    <div class="modal-card intro-tips" width="100px">
      <header class="modal-card-head">
        <div class="logo-container">
          <img :src="`${clientResourcesUrl}logo.png?alt=media`" alt="App Logo" class="app-logo">
        </div>
        <p :class="['modal-card-title','has-text-center', $mq]">{{ title }}</p>
      </header>            
      <component 
        :is="currentComponent"
        @set-title="title = $event"
        @width-change="setModalWidth"
        @reset="reset"
        @set-prev-route="setPrevRoute"
        @set-next-route="setNextRoute"
        @set-next-button-label="setNextButtonLabel"
        @set-next-button-enabled="setNextButtonEnabled"
        @set-next-button-icon="setNextButtonIcon"
        @set-prev-button-label="setPrevButtonLabel"
        @set-prev-button-enabled="setPrevButtonEnabled"
        @set-prev-button-icon="setPrevButtonIcon"
        @set-show-next="setShowNext"
        @set-show-prev="setShowPrev"
        @set-show-controls="setShowControls"
        :next-button-clicked="nextButtonClicked"
        :prev-button-clicked="prevButtonClicked"
      ></component>
      <footer class="modal-card-footer">    
        <LeadWorkflowNavigationButtons      
          v-if="showControls" 
          :next-button-label="nextButtonLabel"
          :next-button-enabled="nextButtonEnabled"
          :next-button-icon="nextButtonIcon"
          :prev-button-label="prevButtonLabel"
          :prev-button-enabled="prevButtonEnabled"
          :prev-button-icon="prevButtonIcon"
          :show-next="showNext"
          :show-prev="showPrev"
          :next-route="nextRoute"
          :prev-route="prevRoute"
          @next-button-clicked="handleNextButtonClick"
          @prev-button-clicked="handlePrevButtonClick"
          @next-button-mouseup="nextButtonClicked = false"
          @prev-button-mouseup="prevButtonClicked = false"
        ></LeadWorkflowNavigationButtons>
      </footer>      
    </div>        
  </b-modal>    
</template>

<style>
  .flow-modal .modal-card-head {
    /* display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    flex-wrap: wrap;
    flex-basis: 50%; */
    position: relative;
  }

  .flow-modal .app-logo {
    width: 100px;
    
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 0 0 20px;
  }

  .app-name {
    font-size: 1.2em;
  }

  .flow-modal .modal-card-title {
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
  }

  .flow-modal .modal-card-title.sm {
    padding-left: 3.8em;
  }

  .flow-modal p.modal-card-title.xs {
    font-size: 1.2em;
    margin-left: 70px;
  }
</style>

<script>
import Introduction from './lead_workflow_components/Introduction.vue'
import Tutorial from './lead_workflow_components/Tutorial.vue'
import AddressSearch from './lead_workflow_components/AddressSearch.vue'
import GetMeasurements from './lead_workflow_components/GetMeasurements.vue'
import Results from './lead_workflow_components/Results.vue'
import LeadWorkflowNavigationButtons from './lead_workflow_components/LeadWorkflowNavigationButtons.vue'

export default {
    name: 'FlowModal',
    components: {
      LeadWorkflowNavigationButtons
    },
    props: {
      firstStepName: {
        type: String
      }
    },
    data(){
      return {
        title: '',
        maxWidth: 200,
        components: {
          'introduction': Introduction,
          'address-search': AddressSearch,
          'tutorial': Tutorial,
          'get-measurements': GetMeasurements,
          'results': Results      
        },
        'prevRoute': '',
        'nextRoute': '',
        'nextButtonLabel': 'Next Step',
        'nextButtonEnabled': true,
        'nextButtonIcon': '',
        'prevButtonLabel': 'Previous Step',
        'prevButtonEnabled': true,
        'prevButtonIcon': '',
        'showNext': false,
        'showPrev': false,
        'nextButtonClicked': false,
        'prevButtonClicked': false,
        'showControls': true
      }
    },
    computed: {     
      currentComponent() {
        return this.components && (this.components[this.$route.params.stepName] || this.components[this.firstStepName]);
      }
    },
    watch: {
      currentStepName: {
        handler(val) {
          console.log(val)
        },
        immediate: true
      },
      currentComponent: {
        handler(val) {
          console.log(val)
        },
        immediate: true
      }
    },
    methods: {
      setModalWidth(width) {
        this.maxWidth = width;
      },
      reset() {
        console.log('resetting state');
        this.$store.dispatch('resetState');
      },
      handleNextButtonClick() {
        this.nextButtonClicked = true;
      },
      handlePrevButtonClick() {
        this.prevButtonClicked = true;
      },
      setShowControls(show) {
        this.showControls = show;
      },
      setPrevRoute(route) {
        this.prevRoute = route;
      },
      setNextRoute(route) {
        this.nextRoute = route;
      },
      setNextButtonLabel(label) {
        console.log('setting next button label', label)
        this.nextButtonLabel = label;
      },
      setNextButtonEnabled(enabled) {
        this.nextButtonEnabled = enabled;
      },
      setNextButtonIcon(icon) {
        this.nextButtonIcon = icon;
      },
      setPrevButtonLabel(label) {
        this.prevButtonLabel = label;
      },
      setPrevButtonEnabled(enabled) {
        this.prevButtonEnabled = enabled;
      },
      setPrevButtonIcon(icon) {
        this.prevButtonIcon = icon;
      },
      setShowNext(show) {
        this.showNext = show;
      },
      setShowPrev(show) {
        this.showPrev = show;
      }
    }
}
</script>