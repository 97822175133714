<template>
  <b-modal class="flow-modal" :can-cancel="true" :has-modal-card="true" scroll="clip" v-model="isModalActive">
    <header class="modal-card-head">      
      <div class="logo-container">
        <img :src="`${clientResourcesUrl}logo.png?alt=media`" alt="App Logo" class="app-logo">
      </div>
      <p :class="['modal-card-title','has-text-center', $mq]">Help</p>
      <button
        type="button"
        class="delete"
        @click="close"/>
    </header>
    <div class="modal-card intro-tips" width="100px">
      <Tutorial 
        :show-controls="true"
        :show-prev="false"
        :prev-route="null"        
        next-button-label="Next Tip"
        all-videos-viewed-action="event"
        @all-videos-viewed="close"
      />
    </div>        
  </b-modal>    
</template>

<style>
  .flow-modal .modal-card-head {
    position: relative;
  }

  .flow-modal .app-logo {
    width: 100px;
    
    position: absolute;
    top: 0;
    left: 0;
    padding: 20px 0 0 20px;
  }

  .app-name {
    font-size: 1.2em;
  }

  .flow-modal .modal-card-title {
    text-align: center;
    font-size: 1.3em;
    font-weight: bold;
  }

  .flow-modal .modal-card-title.sm {
    padding-left: 3.8em;
  }
</style>

<script>
import Vuex from 'vuex'
import Tutorial from './lead_workflow_components/Tutorial.vue'

export default {
  name: 'TutorialModal',
  components: {
    Tutorial
  },
  data() {
    return {
      isModalActive: false
    }
  },
  watch: {
    isModalActive(val) {
      this.setTutorialModalVisibility(val);
    },
    tutorialModalVisible(val) {
      this.isModalActive = val;
    }
  },
  computed: {
    tutorialModalVisible() {
      return this.$store.state.tutorialModalVisible;
    }
  },
  methods: {
    ...Vuex.mapActions([
      'setTutorialModalVisibility'
    ]),
    close() {
      this.setTutorialModalVisibility(false);
    }
  }
}
</script>