  ```vue
<template>
  <div class="modal-card-body">      
    <!-- Wrap the image and the table in a new div -->
    <div :class="['results-container', $mq]">
      <div class="table-container">
        <div class="container mt-2">
          <b-table
            :mobile-cards="false"
            :data="shapes"                    
            :row-class="(row, index) => row.convertedCalculated < 0 && 'is-danger'">
            <b-table-column field="displayIndex" label="#" v-slot="props">
              {{ props.row.displayIndex }}
            </b-table-column>
            <b-table-column field="labelText" label="Label" v-slot="props">
              {{ props.row.labelText }}
            </b-table-column>
            <b-table-column field="convertedCalculated" label="Measurement" v-slot="props">
              {{ props.row.convertedCalculated.toLocaleString() }}
            </b-table-column>
            <b-table-column field="suffix" label="Unit" v-slot="props">
              {{ props.row.suffix }}
            </b-table-column>
          </b-table>
        </div>          
      </div>
      <div class="total-area">
        <p class="result"><vector-polygon-icon />Total Area: {{ totalShapeCalculatedConverted.toLocaleString() }} {{ measureSuffix }}</p>
      </div>                
      <p class="estimate-note"><information-icon />{{ config.verbiage.results_estimate_notice }}</p>
      <div class="what-next" v-html="whatNextParagraph"></div>
    </div>
  </div>
</template>

<script>
import LeadWorkflowNavigationButtons from '@/components/lead_workflow_components/LeadWorkflowNavigationButtons.vue';
import { mapGetters, mapState } from 'vuex';
import MeasureMap from '../MeasureMap.vue';

export default {
  name: 'ContactInfo',
  components: {
    LeadWorkflowNavigationButtons
  },
  props: {
    nextButtonClicked: {
      type: Boolean,
      default: false
    },
    prevButtonClicked: {
      type: Boolean,
      default: false
    }    
  },
  data() {
    return {
      title: 'Your results are ready!',
      columns: [
        {
          field: 'displayIndex',
          label: '#',
        },
        {
          field: 'labelText',
          label: 'Label',
        },
        {
          field: 'convertedCalculated',
          label: 'Measurement',
        },
        {
          field: 'suffix',
          label: 'Unit',
        },
      ]
    }
  },
  watch: {
    nextButtonClicked: function(val) {
      if (val) {
        this.viewOnMap();
      }
    },
    prevButtonClicked: function(val) {
      if (val) {
        this.confirmStartOver();
      }
    }
  },
  computed: {
    ...mapGetters([
      'shapes'
    ]),
    ...mapGetters('mapSharing', [
      'sharingLink'
    ]),
    ...mapState('mapSharing', [
      'mapScreenshotUrl'
    ]),
    ...mapState('contact', [
      'productInterest'
    ]),
    ...mapGetters(['totalShapeCalculatedConverted','measureSuffix']),
    ...mapState(['meta']),
    whatNextParagraph() {
      return this.config.verbiage.results_what_next_paragraph.replace('{{ customerEmail }}', this.$store.state.contact.email);
    }
  },
  methods: {
    confirmStartOver() {
      this.$store.dispatch('dialogs/confirm', { 
        priority: 0, 
        message: `Starting over will remove all measurements. Your results will only be available in the email you received.<br>
        <br><span class="has-text-weight-bold">Are you sure?</span>`,
        config: {
          title: 'Warning',
          confirmText: 'Yes, start over',
          cancelText: 'Cancel',
          type: 'is-primary',
          onConfirm: this.startOver
        }
      });
    },
    viewOnMap() {
      console.log(this.sharingLink);
      window.open(this.sharingLink, '_blank');
      // this.startOver();
      // window.location.href = this.sharingLink;      
    },
    async startOver() {
      try {
        this.$store.commit('SET_LOADING', { loading: true });
        await this.$store.dispatch('initNewMap');
        this.$router.push('/flow/introduction');
      } finally {
        this.$store.commit('SET_LOADING', { loading: false });
      }
    }
  },
  mounted() {
    this.$emit('set-title', this.verbiage.results_title);
    this.$emit('set-next-button-label', 'VIEW ON MAP');
    this.$emit('set-next-button-icon', 'map-icon');
    this.$emit('set-show-next', true);
    this.$emit('set-prev-button-label', 'Start Over');
    this.$emit('set-show-prev', true);
    this.$emit('set-prev-route', '');
    this.$emit('set-next-button-enabled', true);
    this.$emit('set-prev-button-enabled', true);
  }
}
</script>

<style>
  div.total-area {
    border: 1px solid #ddd;
    padding: 10px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: #f9f9f9;
  }

  
  .vector-polygon-icon {
    margin-right: 5px;
  }

  div.total-area p.result {
    font-size: 1.1em;
    font-weight: bold;
    
    text-align: center;
    color: #485e2f;
    
    text-shadow: 0 0 10px #ccc;
  }

  p.estimate-note {
    font-size: 0.8em;
    margin-top: 10px;
  }

  div.what-next {
    margin-top: 15px;
    padding: 10px 10px 0;
    border-top: 1px solid #ccc;
  }
  b-table {
    position: relative;
  }

  b-table template[slot="footer"] {
    position: sticky;
    bottom: 0;
    background-color: inherit;
  }

  tr.is-danger {
    background: #ff3860;
    color: #fff;
  }
</style>