/* App.vue */

<template>
  <div style="display: contents">
    <b-loading :active="loading"></b-loading>
    <dialog-manager></dialog-manager>
    <router-view @map-init="inited = true"></router-view>
    <router-view name="dialog" v-slot="{ Component }">
      <transition name="fade">
        <component :is="Component"></component>
      </transition>

    </router-view>
    <router-view name="search"></router-view>
    <router-view name="toolbar"></router-view>
  </div>
</template>

<script>
import Vuex from 'vuex';
import query from 'query-string'
import { get, throttle, pick, omit } from 'lodash'
import moment from 'moment'

import Dialog from './components/Dialog'

export default {
  components: { 'DialogManager': Dialog },
  data() {
    return {
      throttledSave: null,
      lastActivity: moment(),
      inited: false,
      loadMapId: null
    }
  },
  computed: {
    ...Vuex.mapGetters({    
      'loading': 'loading'
    })
  },
  watch: {
    '$route': {
      handler(to, from) {
        if (to.params.mapId) {
          this.loadMapId = to.params.mapId;
          return;
        }        

        if (!this.inited) {
          this.$store.dispatch('initializeStore');
        }

        if (Object.keys(this.$route.query).length) {
          const utmKeys = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
          const utm = _.defaults(_.pick(this.$route.query, utmKeys), _.zipObject(utmKeys, _.times(utmKeys.length, _.constant(''))));  
          console.log('setting utm', utm)
          this.setUtm(utm);
        }
      },
      immediate: true
    },
    loadMapId(newMapId) {
      if (this.inited && newMapId) {
        this.$store.dispatch('initializeStore', { mapId: newMapId });
      }
    },
    inited: {
      handler(inited) {
        if (!inited) { return; }
        console.log('map inited')
        if (this.loadMapId) {
          this.$store.dispatch('initializeStore', { mapId: this.loadMapId });
          return;
        }

        this.$store.dispatch('initializeStore');
      },
      immediate: true
    }
  },
  methods: {
    initStore() {
      console.log('init store')
      // create throttle function that will run no more than every 5 seconds that stores current Vuex state to localStorage    
      this.throttleSave = throttle((mutation, state) => {
        let copy = omit(state, ['backend','tooltips','estimator','dialogs','currentControlOverlay']);        
        localStorage.setItem('store', JSON.stringify(copy));
        this.lastActivity = moment();
      }, 1000);
      
      this.$store.subscribe(this.throttleSave); 
    },
    ...Vuex.mapActions(['setUtm'])
  },
  mounted() {
    this.initStore();    
  }  
}
</script>