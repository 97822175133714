import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters({
      config: 'siteConfig/config',
      verbiage: 'siteConfig/verbiage',
      clientResourcesUrl: 'siteConfig/clientResourcesUrl'
    })
  }
};